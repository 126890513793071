import React from "react";
import {Card, CardFooter, CardHeader, CardBody, Image, Button, Link, Divider, Avatar} from "@nextui-org/react";
import backIcon from '../images/back.png'
import clockIcon from "../images/clock.png"
import BidDrawer from "../components/biddrawer"

export default function App() {
  return (
    <div className="pb-20">
        <Card
            isFooterBlurred
            radius="small"
            className="border-none"
        >
            <CardHeader className="absolute z-10 top-1 justify-between">
                <Button 
                isIconOnly className="bg-white/50 drop-shadow-lg" 
                as={Link}
                href="javascript:history.back()">
                    <Image 
                    width={18} 
                    src={backIcon}/>
                </Button>
                <div className="bg-white/50 rounded-large px-4 py-1 flex items-start gap-1 pl-2">
                    <Image
                        width={18}
                        src={clockIcon}
                        className="rounded-none"
                    />
                    <p className="text-small text-black/70 font-medium">2h 45m 32s Till Auction Ends</p>
                </div>
            </CardHeader>
            <Image
                className="z-0 object-cover rounded-none"
                width={'100%'}
                src="https://www.bobswatches.com/rolex-blog/wp-content/uploads/2020/03/Rolex_GMT_116710_5D3_9644-2-Edit-1.jpg"
            />
            
            <CardBody className="p-5">
                <p className="text-2xl text-white/80 mb-1">GMT-MASTER II</p>
                <p className="text-large text-white/80">Current bid <b>$12,200</b></p>
            </CardBody>
        </Card>
        <div className="p-5">
            <h3 className="font-medium text-xl mb-3">Description</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div className="flex mt-5 gap-10">
                <span>
                    <p className="text-small font-bold mb-1">
                        New
                    </p>
                    <p className="text-zinc-400 text-small">Condition</p>
                </span>
                <span>
                    <p className="text-small font-bold mb-1">
                        2022
                    </p>
                    <p className="text-zinc-400 text-small">Year</p>
                </span>
                <span>
                    <p className="text-small font-bold mb-1">
                        6 Days ago
                    </p>
                    <p className="text-zinc-400 text-small">Listed</p>
                </span>
            </div>
        </div>
        <Divider />
        <div className="p-5">
            <h3 className="font-medium text-xl mb-3">Bidders</h3>
            <div className="flex gap-3 items-center">
            <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
            <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026704d" />
            <Avatar src="https://i.pravatar.cc/150?u=a04258114e29026702d" />
            </div>
        </div>
        <BidDrawer/>
    </div> 
  );
}