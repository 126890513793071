import React from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Image, Input} from "@nextui-org/react";

import plusIcon from '../images/plus.png'
import minusIcon from '../images/minus.png'
import clockIcon from "../images/clock.png"

export default function App() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [modalPlacement, setModalPlacement] = React.useState("bottom");
  const [amount, setAmount] = React.useState(130);
  const handleAmountChange = (delta) => {
    setAmount((prevAmount) => {
        // Ensure prevAmount is a number, in case of any unexpected behavior
        const currentAmount = parseFloat(prevAmount) || 0; // Default to 0 if NaN
        const change = parseFloat(delta); // Ensure delta is a valid number
        if (!isNaN(change)) {
        return (currentAmount + change).toFixed(2); // Perform addition with floats
        }
        return currentAmount.toFixed(2); // Return original amount if invalid delta
    });
  };

  return (
    
    <div>
        <div className="fixed z-10 bottom-0 pb-5 w-full flex justify-center bg-gradient-to-t from-white/20 to-transparent">
            <Button onPress={onOpen} className="py-6 px-5 pr-3 rounded-full bg-white text-black shadow-large">Place a bid
                <span className="rounded-full bg-black text-white p-2 px-3 bg-black ml-1">$5 bid step</span>
            </Button>
        </div>
      <Modal 
        isOpen={isOpen} 
        placement={modalPlacement}
        onOpenChange={onOpenChange} 
        className="max-w-full overflow-hidden"
      >
        <ModalContent className="w-full">
          {(onClose) => (
            <>
              <ModalHeader className="flex justify-between gap-1 items-end pt-10">
                <p className="font-bold">Place a bid</p>
                <p className="text-small text-zinc-450">Time left <b className="font-bold text-black">2h:45m:32s</b></p>
              </ModalHeader>
              <ModalBody>
                <div className="flex justify-between items-center">
                    <Button isIconOnly color="none" onPress={() => handleAmountChange(-5)}>
                        <Image src={minusIcon}/>
                    </Button>
                    <div className="text-center">
                        {/* <p className="text-zinc-900 text-2xl font-bold">${amount}</p> */}
                        <div className="flex items-center">
                            <span className="mr-2 text-lg font-bold">$</span>
                            <Input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                color={'default'}
                                className="text-zinc-900 font-bold bidinput"
                                style={{ textAlign: "center", fontSize: "24px", maxWidth: "200px" }} // Center the text within the input
                            />
                        </div>
                        <p className="text-sm text-zinc-400 mt-2">Current bid <b className="text-zinc-900 pl-1">$125.00</b></p>
                    </div>
                    <Button isIconOnly color="none" onPress={() => handleAmountChange(5)}>
                        <Image src={plusIcon}/>
                    </Button>
                </div>
              </ModalBody>
              <ModalFooter className="flex justify-center">
                <Button className="rounded-full bg-black text-white px-5 bg-black" onPress={onClose}>
                  Place a bid
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
