import React from "react";
import {Card, CardFooter, CardHeader, CardBody, Image, Button} from "@nextui-org/react";

const CustomCard = ({
    headerTitle,
    cardImage
}) => {
    return (
        <Card
            isFooterBlurred
            radius="lg"
            className="border-none"
        >
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                <p className="text-tiny text-white/60 uppercase font-bold">What to watch</p>
                <h4 className="text-white font-medium text-large">{headerTitle}</h4>
            </CardHeader>
            <Image
            alt="Woman listing to music"
            className="z-0 object-cover"
            width={'100%'}
            src={cardImage}
            />
            <CardFooter className="justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
            <p className="text-tiny text-white/80">Available soon.</p>
            <Button className="text-tiny text-white bg-black/20" variant="flat" color="default" radius="lg" size="sm">
                Notify me
            </Button>
            </CardFooter>
        </Card>
    )
}

export default CustomCard;