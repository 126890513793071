import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Test from './pages/test'
import AuctionItem from './pages/actionItem'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Test />} />
        <Route path="/item" element={<AuctionItem />} />
      </Routes>
    </Router>
  );
}

export default App;
