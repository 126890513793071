import React from "react";
import {Card, CardFooter, CardHeader, Image, Button, Link} from "@nextui-org/react";
import clockIcon from "../images/clock.png"

const CustomCard = ({
    headerTitle,
    cardImage
}) => {
    return (
        <Card
            isFooterBlurred
            radius="lg"
            className="border-none"
        >
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                {/* <p className="text-tiny text-white/60 uppercase font-bold">What to watch</p>
                <h4 className="text-white font-medium text-large">{headerTitle}</h4> */}
                <div className="bg-white/80 rounded-full px-4 py-1 flex items-center gap-1 pl-2">
                    <Image
                        width={'20'}
                        src={clockIcon}
                    />
                    <p className="text-small text-black font-medium">2h 45m 32s</p>
                </div>
            </CardHeader>
            <Image
            alt="Woman listing to music"
            className="z-0 object-cover"
            width={'100%'}
            src={cardImage}
            />
            <CardFooter className="p-5 justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
            <div className="w-full">
                <p className="text-small text-white/80 mb-1">GMT-MASTER II</p>
                <p className="text-medium font-medium text-white/80">$12,200</p>
            </div>
            <Button className="text-xs font-medium text-black bg-white/100 p-5" 
            variant="flat" color="default" radius="lg" size="sm"
            as={Link}
            href="/item">
                Place a bid
            </Button>
            </CardFooter>
        </Card>
    )
}

export default CustomCard;