import React from "react";
import Card from '../components/card'
import BidCard from '../components/bidcard'

export default function App() {
  return (
    <div className="p-5">
        <h2 className="text-white font-bold text-xl mb-3">COMING SOON!</h2>
        <div className="grid grid-cols-2 gap-4">
            <Card 
                headerTitle={"GMT-MASTER II"} 
                cardImage={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSddJD5yv42h0QRDEcPYIYaighySgy16BQHtfPiRD1jEEm8LsGD_mOsQ3DqNJUpDaOFGd4&usqp=CAU"}
            />
            <Card 
                headerTitle={"GMT-MASTER II"} 
                cardImage={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSddJD5yv42h0QRDEcPYIYaighySgy16BQHtfPiRD1jEEm8LsGD_mOsQ3DqNJUpDaOFGd4&usqp=CAU"}
            />
        </div>

        {/* <h2 className="text-white font-medium text-xl mb-3 mt-10">Discover</h2> */}
        <div
        className="overflow-x-auto whitespace-nowrap py-5 mb-2 mt-8"
        >
            <a
            href=""
            className="text-white font-small text-l inline-block mx-2 hover:underline active"
            >
            Discover
            </a>
            <a
            href=""
            className="text-white font-small text-l inline-block mx-2 hover:underline text-white/50"
            >
            Upcoming
            </a>
            <a
            href=""
            className="text-white font-small text-l inline-block mx-2 hover:underline text-white/50"
            >
            My auctions
            </a>
        </div>

        <div className="grid grid-cols-1 gap-4">
            <BidCard
                headerTitle={"GMT-MASTER II"} 
                cardImage={"https://www.bobswatches.com/rolex-blog/wp-content/uploads/2020/03/Rolex_GMT_116710_5D3_9644-2-Edit-1.jpg"}
            />
            <BidCard
                headerTitle={"GMT-MASTER II"} 
                cardImage={"https://cdn.wristler.eu/image/6e4e4fb0ac637793dcf78fd55b383255-"}
            />
            <BidCard
                headerTitle={"GMT-MASTER II"} 
                cardImage={"https://cdn2.chrono24.com/images/uhren/28495719-ei4xpcf8jy5c0y1dzuqvc0zg-ExtraLarge.jpg"}
            />
        </div>
    </div> 
  );
}